
import { defineComponent, isProxy, ref, shallowRef, toRaw } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTDealerMenu from "@/layout/header/partials/DealerMenu.vue";
import KTUserMenu from "@/layout/header/partials/DWUser.vue";
import ApiService from "@/core/services/ApiService";
import DWmap from "@/components/map/DWmap.vue";
import { MultiSelectOption } from "./Models/multiSelectOption";

export default defineComponent({
  name: "topbar",
  emits: ["source-selected", "dealer-selected"],
  components: {
    KTNotificationsMenu,
    KTDealerMenu,
    KTUserMenu,
  },
  data() {
    return {
      dealers: [] as any[],
      componentKey: ref(0),
      dealerComponent: ref(0),
      multiSelectOptions: [] as any[],
      filteredOptions: [] as any[],
      selectedDealer: [] as any[],
      selectedSource: "DMS" as string,
      dealer: Object,
      dealerDms:"" as string,
      remoteData:null as any
    };
  },

  created() {
    this.$emit("source-selected", this.selectedSource);
    this.fetchData();
  },
  methods: {
    selectSource(e) {
      if (e.target.options.selectedIndex > -1) {
        var source =
          e.target.options[e.target.options.selectedIndex].firstChild.data;
        this.$emit("source-selected", source);
        this.selectedSource = source;
      }
    },
    findDealers(e) {
      this.filteredOptions = [];
      let skatoProxy = this.multiSelectOptions.filter((x) =>
        x.name.toLowerCase().includes(e.toLowerCase())
      );
      let rawObject = { ...skatoProxy } as any[];
      for (const [key, proxy] of Object.entries(rawObject)) {
        let rawItem = { ...proxy };
        this.filteredOptions.push(rawItem);
      }
      this.filteredOptions = JSON.parse(JSON.stringify(this.filteredOptions));
      //(this.filteredOptions);
      return this.filteredOptions;
    },
    initiateSelect() {
      //console.log(this.selectedDealer);
      this.$emit("dealer-selected", this.selectedDealer);
       this.fetchDealer(this.selectedDealer)
    },
    fetchDealer(dealerId) {
      ApiService.axios
        .post("/dealer/GetDealerById", { dealerId: dealerId, roleId: 0 })
        .then((response) => {
          this.dealer = response.data.dealer;
          let dmss = response.data.dealerDMS.map(x=> {
            return x.dMsID
          });
          this.dealerDms = dmss.join(" & ");
          //! TODO Make the actual call and bring the dms names
          this.dealerDms = this.dealerDms.replaceAll("1","CDK").replaceAll("2","DataCube").replaceAll("3","DealerDirect").replaceAll("4","DealerVault")
          this.dealerComponent +=1
          //console.log(this.dealer);
        })
        .catch((error) => console.log(error));
    },
    fetchData() {
      ApiService.axios
        .post("/Account/GetRemoteStorage", {})
        .then((response) => {
          this.remoteData = response.data;
          ApiService.axios
            .get(
              "/dealer/GetAllDealerNames" /*, {
              UserId: response.data.userId,
            }*/
            )
            .then((res) => {
              this.dealers = res.data;
              //console.log(this.dealers)
              this.selectedDealer = this.dealers[0].id;
              this.$emit("dealer-selected", this.selectedDealer);
              this.fetchDealer(this.selectedDealer)
              this.componentKey += 1;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
