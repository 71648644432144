
import { defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";
//import KTMenu from "@/layout/header/Menu.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "KTHeader",
  emits: ["source-selected","dealer-selected"],
  props: {
    title: String,
  },
  components: {
    KTTopbar,
    //KTMenu,
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
  methods: {
    forwardSource(source) {      
      this.$emit('source-selected', source);
    },
    forwardDealer(dealer) {      
      this.$emit('dealer-selected', dealer);
    }
  }
});
